export default {
  user: {
    name: 'Usuarios',
    list: [
      { value: 'admin', label: 'Administrador' },
      { value: 'readonly', label: 'Sólo lectura' }
    ]
  },
  customer: {
    name: 'Clientes',
    list: [
      { value: 'admin', label: 'Administrador' },
      { value: 'readonly', label: 'Sólo lectura' }
    ]
  }
}