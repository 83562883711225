const local = {
  ga_analytics: '',
  s3: {
    BUCKET: "files.manager.neokodelabs.com"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokodelabs.com/manager"
  },
  cognito: {
    Auth: {
      cookieStorage: {
        domain: "localhost",
        secure: false,
      },
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_sH4WP7LfF',
      userPoolWebClientId: '3gt9joqhuvedrqn4s72q2pom99',
      identityPoolId: 'us-east-1:e7daf1b9-7124-4ce9-bdc3-9f74f101e549'
    }
  },
  terms: 'https://www.neokodelabs.com/terms',
  policy: 'https://www.neokodelabs.com/privacy'
};

const dev = {
  ga_analytics: '',
  s3: {
    BUCKET: "files.manager.neokodelabs.com"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokodelabs.com/manager"
  },
  cognito: {
    Auth: {
      cookieStorage: {
        domain: "neokodelabs.com",
        secure: true,
      },
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_sH4WP7LfF',
      userPoolWebClientId: '3gt9joqhuvedrqn4s72q2pom99',
      identityPoolId: 'us-east-1:e7daf1b9-7124-4ce9-bdc3-9f74f101e549'
    }
  },
  terms: 'http://neokodelabs.com/terms',
  policy: 'http://neokodelabs.com/policy'
};

const prod = {
  ga_analytics: 'G-WF5GN5JK0R',
  s3: {
    BUCKET: "files.manager.neokode.cl"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.neokode.cl/manager"
  },
  cognito: {
    Auth: {
      cookieStorage: {
        domain: "neokode.cl",
        secure: true,
      },
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_uhuKVpT9D',
      userPoolWebClientId: 'fvrk0gnantid3coc9vi3nvbn4',
      identityPoolId: 'us-east-1:e45a61b0-8aa3-4ba3-b15a-87499d3c16f2'
    }
  },
  terms: 'https://www.neokode.cl/terms',
  policy: 'https://www.neokode.cl/policy'
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : process.env.REACT_APP_ENV === 'development'
  ? dev
  : local;

console.log("REACT_APP_ENV:" + process.env.REACT_APP_ENV);

export default {
  version: '1.0.1',
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
