import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Button,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import { invokeApig } from '../lib/awsLib';
import NeokodeButton from '../components/NeokodeButton';
import CustomerUserForm from '../containers/customer/CustomerUserForm';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  gridMaxWidth: {
      [theme.breakpoints.up('md')]: {
          maxWidth: theme.breakpoints.values.md,
      },
  },
  paper: {
      padding: theme.spacing(2),
  },
  
}));

function CustomerUserPage(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const { id, idu } = useParams();
  const [customer, setCustomer] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getCustomer();
    getUser();
  }, []);

  const getCustomer = async() => {
    if (id) {
        try {
            setIsLoadingCustomer(true);
            const response = await invokeApig({
                path: `/account/${id}`
            });
            if (response.code === 200) {
              setCustomer(response.payload);
            }
        } catch (error) {
          console.log(error);
        }
    }
    setIsLoadingCustomer(false);
  }

  const getUser = async() => {
    if (idu) {
        try {
            setIsLoading(true);
            const response = await invokeApig({
                path: `/account/user/${idu}`,
            });
            if (response.code === 0) {
              setUser(response.data);
            } else {
              if (response.errorType === 'UserNotFoundException') {
                setMessage('El usuario no existe');
              } else {
                setMessage('No pudimos obtener los datos del usuario. Intanta nuevamente o comunícate con Soporte');
              }
              setIsError(true);
            }
        } catch (error) {
          setMessage('Error al obtener los datos del usuario. Intanta nuevamente o comunícate con Soporte');
          setIsError(true)
        }
        setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  const goUserList = () => {
    props.history.push(`/customers/${id}/users`);
  }

  const renderCustomer = () => (
    isLoadingCustomer ?
    <CircularProgress />
    :
    (
      customer ?
      <Grid container spacing={2}>
        <Grid item xs={10} md={10}>
          <Typography variant='h5'>Datos del cliente</Typography>
        </Grid>
        <Grid item xs={2} md={2}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            component={RouterLink} to={`/customers/${id}/users`}
          >
            Volver
          </Button>
        </Grid>

        <Grid item xs={4} md={2}>
          <b>RUT</b>
        </Grid>
        <Grid item xs={8} md={4}>
          {customer.id}
        </Grid>

        <Grid item xs={4} md={2}>
          <b>Nombre</b>
        </Grid>
        <Grid item xs={8} md={4}>
          {customer.name}
        </Grid>

        <Grid item xs={4} md={2}>
          <b>Contacto</b>
        </Grid>
        <Grid item xs={8} md={4}>
          {customer.contact}
        </Grid>

        <Grid item xs={4} md={2}>
          <b>Estado</b>
        </Grid>
        <Grid item xs={8} md={4}>
          {customer.status}
        </Grid>
      </Grid>
      :
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          No pudimos cargar los datos del cliente, asegúrate que es el RUT {id} es el correcto antes de continuar.
        </Grid>
      </Grid>
    )
  );

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxWidth}>
        <Paper className={classes.paper}>
          {renderCustomer()}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxWidth}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            {!!idu ? 'Editar' : 'Crear'} usuario
          </Typography>
          {
            isLoading ?
            <CircularProgress />
            :
            (
              isError ?
              <>
                <Alert severity="error">
                    {message}
                </Alert>
                <NeokodeButton
                  type="button"
                  variant="contained"
                  className={classes.button}
                  component={RouterLink}
                  to={`/customers/${id}/users`}
                >
                  Volver al listado de usuarios
                </NeokodeButton>
              </>
              :
              <CustomerUserForm {...props} id={id} user={user} onFinish={goUserList} />
            )
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CustomerUserPage;
