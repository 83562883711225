import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, IconButton, Grid, Paper, TextField, Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DataGrid, esES } from '@material-ui/data-grid';
import * as Icons from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import NoRowsOverlay from '../components/NoRowsOverlay';
import QuickSearchToolbar from '../components/QuickSearchToolbar';
import { invokeApig } from '../lib/awsLib';
import NeokodeButton from '../components/NeokodeButton';
import { has_access } from '../lib/access';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
  },
  gridMaxMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  breadcrumbContainer: {
    padding: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    margin: '0',
  },
  changeButton: {
    backgroundColor: '#2196f3',
    '&:hover': {
      backgroundColor: '#2196f3',
    },
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  },
  searchInput: {
    marginRight: '10px',
  },
  editIcon: {
    color: '#2196f3',
  },
  enableIcon: {
    color: '#00b31f',
  },
  disableIcon: {
    color: '#f44336',
  },
  rootSearch: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textFieldSearch: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  buttonsDialogContainer: {
    padding: '10px 25px',
  },
  refreshIcon: {
    color: '#666',
    margin: '0 15px'
  },
  button: {
    margin: '15px',
  },
  confirmUserText: {
    textAlign: 'center',
  },
  green: {
    backgroundColor: '#00b31f',
    color: '#ffffff',
  },
  red: {
    backgroundColor: '#f44336',
    color: '#ffffff',
  },
  yellow: {
    backgroundColor: '#ff9800',
    color: '#ffffff',
  },
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function CustomersPage(props) {
  const access = props.params.access;
  const classes = useStyles();
  const [isGlobalError, setIsGlobalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [customer, setCustomer] = useState({});
  const [isChangeState, setIsChangeState] = useState(false);
  const [message, setMessage] = useState('');
  const [customers, setCustomers] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = async () => {
    try {
      setIsLoading(true);
      setIsGlobalError(false);
      setCustomers([]);
      setRows([]);
      setSearchText('');
      try {
        let lastKey = undefined;
        do {
          const res = await invokeApig({
            path: "/account/find",
            method: "POST",
            body: {
              "LastEvaluatedKey": lastKey ? lastKey : undefined,
              "limit": 50
            }
          });
          if (res.code === 200) {
            setIsLoading(false);
            const newCustomers = [...customers, ...res.payload.owners];
            setCustomers(newCustomers);
            setRows(newCustomers);
            lastKey = res.payload.LastEvaluatedKey;
          } else {
            if (response.code === 403) {
              setMessage(response.message);
              setIsGlobalError(true);
              break;
            } else {
              setMessage('Error al obtener los clientes. Intenta nuevamente o comunícate con Soporte');
              setIsGlobalError(true);
              break;
            }
          }
        } while (lastKey !== null);
      } catch (e) {
        this.setState({isError: true});
      }
    } catch (error) {
      console.error('Error in getCustomers:', error);
      setMessage('Error al obtener los clientes. Intenta nuevamente o comunícate con Soporte');
      setIsGlobalError(true);
    }
    setIsLoading(false);
  }

  const changeState = async (customer) => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/customer/state`,
        method: "PUT",
        body: {
          customer: customer.id,
          //state: newState,
        }
      });
      if (response.errorMessage) {
        setMessage(response.errorMessage);
        setIsError(true);
      } else {
        setMessageToast('Se ha enviado un correo al usuario con las instrucciones para cambiar la contraseña');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      console.error('Error in getAccess:', error);
      setMessage('Ocurrió un error al resetear la clave. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
    getCustomers();
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = customers.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const handleShowChangeState = (customer) => {
    setCustomer(customer);
    setIsChangeState(true);
    setIsError(false);
    setMessage('');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirmAction = () => {
    if (isChangeState) {
      changeState(customer);
    }
  }

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID usuario',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Notificación NK',
      flex: 1,
    },
    {
      field: 'contact',
      headerName: 'Contacto',
      flex: 1,
    },
    {
      field: 'creationDate',
      headerName: 'Fecha de alta',
      flex: 1,
      renderCell: (params) => (
        moment(params.value).utcOffset(params.row.creationDateZone).format('DD-MM-YYYY HH:mm')
      ),
    },
    {
      field: 'status',
      headerName: 'Estado',
      //flex: 1,
      sortable: false,
      renderCell: (params) => (
        params.value === 'Activo' ?
        <Chip
          size="small"
          label="Activo"
          className={classes.green}
        />
        :
        <Chip
          size="small"
          label={params.value}
          className={classes.yellow}
        />
      ),
    },
    {
      field: 'registerPayment',
      headerName: 'Pago automático',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        params.value ?
        <Chip
          size="small"
          label="Pago automático"
          icon={<Icons.Done style={{ color: '#ffffff' }} />}
          className={classes.green}
        />
        :
        <Chip
          size="small"
          label="Pago manual"
          icon={<Icons.HighlightOff style={{ color: '#ffffff' }} />}
          className={classes.red}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false ,
      renderCell: (params) => {
        const isActive = params.row.status === 'Activo';
        return (
          has_access(access, 'customer', ['admin']) &&
          <>
            <IconButton component={RouterLink} to={`/customers/edit/${params.id}`} className={classes.editIcon}>
              <Icons.Edit />
            </IconButton>
            <IconButton component={RouterLink} to={`/customers/${params.id}/users`} className={classes.userIcon}>
              <Icons.Group />
            </IconButton>
            <IconButton onClick={() => handleShowChangeState(params.row)} className={isActive? classes.disableIcon : classes.enableIcon}>
              {
                isActive ?
                <Icons.RemoveCircle />
                :
                <Icons.CheckCircle />
              }
            </IconButton>
          </>
        ); 
      },
    }
  ];

  const showConfirmDialog = () => {
    return (
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          <p className={classes.confirmUserText}><b>Cliente</b>: {customer.name}</p>
          <Alert severity="info">
            <p>{ isChangeState && 'Se va a modificar el estado del cliente.' }</p>
            <p><b>¿Estás seguro que quieres continuar con la acción?</b></p>
          </Alert>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseDialog}
            disabled={isLoading}
          >
            No, cerra esta ventana
          </Button>
          <Button
            type="button"
            color={"primary"}
            variant="contained"
            className={classes.changeButton}
            onClick={handleConfirmAction}
            disabled={isLoading}
          >
            Sí, confirmo
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderError = () => (
    <>
      <Alert severity="error">
          {message}
      </Alert>
      <NeokodeButton
        type="button"
        variant="contained"
        className={classes.button}
        component={RouterLink}
        to="/"
      >
        Volver al inicio
      </NeokodeButton>
    </>
  );

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Administración de Clientes
          </Typography>

          {
            isGlobalError ?
            renderError()
            :
            <>
              <div style={{ height: '690px', width: '100%' }}>
                <DataGrid
                  getRowId={(row) => row.id}
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  loading={isLoading}
                  components={{
                    NoRowsOverlay: NoRowsOverlay,
                    Toolbar: QuickSearchToolbar
                  }}
                  componentsProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(''),
                      onRefresh: () => getCustomers(),
                      addButtonUrl: has_access(access, 'customer', ['admin']) ? '/customers/create': false,
                      addButtonText: has_access(access, 'customer', ['admin']) ? 'Nuevo Cliente': false,
                    },
                  }}
                  localeText={esES.props.MuiDataGrid.localeText}
                />
              </div>
              {showConfirmDialog()}
            </>
          }
        </Paper>
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default CustomersPage;