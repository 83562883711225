import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Paper, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { invokeApig } from '../../lib/awsLib';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    //width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: '#2196f3',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2086e3',
    },
  },
  autocomplete: {
    '& .MuiInputBase-input': {
      width: '100%',
    }
  },
}));

const FilterPanel = ({ onFilter }) => {
  const classes = useStyles();
  const states = [
    {value: '', label: 'Todos'},
    {value: 'Sin asignar', label: 'Sin asignar'},
    {value: 'En progreso', label: 'En progreso'},
    {value: 'Resuelto', label: 'Resuelto'},
  ]
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [customers, setCustomers] = useState([{value: '', label: 'Todos'}]);
  const [isLoading, setIsLoading] = useState(true);
  const [customerSel, setCustomerSel] = useState({value: '', label: 'Todos'});
  const [statusSel, setStatusSel] = useState({value: '', label: 'Todos'});

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = async () => {
    try {
      setIsLoading(true);
      setCustomers([{value: '', label: 'Todos'}]);
      let lastKey = undefined;
      do {
        const res = await invokeApig({
          path: "/account/find",
          method: "POST",
          body: {
            "LastEvaluatedKey": lastKey ? lastKey : undefined,
            "limit": 50
          }
        });
        if (res.code === 200) {
          const newCustomers = [];
          res.payload.owners.map((owner, index) => {
            newCustomers.push({
              value: owner.id,
              label: owner.name
            });
          });
          setCustomers([...customers, ...newCustomers]);
          setIsLoading(false);
          lastKey = res.payload.LastEvaluatedKey;
        } else {
          break;
        }
      } while (lastKey !== null);
    } catch (error) {
      console.error('Error in getCustomers:', error);
    }
    setIsLoading(false);
  }

  const handleFilter = () => {
    const customer = customerSel ? customerSel.value : undefined;
    const status = statusSel ? statusSel.value : undefined;
    onFilter({ startDate, endDate, customer, status });
  };

  const today = moment().format('YYYY-MM-DD')

  return (
    <Paper>
      <div>
        <FormControl className={classes.formControl}>
          <TextField
            label="Fecha Inicial"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: "2024-01-01",
              max: endDate || today,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Fecha Final"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: startDate || today,
              max: today,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
        {
            isLoading ?
            <CircularProgress />
            :
            <>
              <Autocomplete
                options={customers}
                getOptionLabel={(option) => option.label || ''}
                getOptionSelected={(option, value) => option.value === value.value}
                renderInput={(params) => (
                  <TextField {...params} label="Cliente" className={classes.autocomplete} />
                )}
                value={customerSel}
                onChange={(event, newValue) => setCustomerSel(newValue)}
              />
            </>
        }
        </FormControl>
        <FormControl className={classes.formControl}>
          <Autocomplete
            options={states}
            getOptionLabel={(option) => option.label || ''}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => (
              <TextField {...params} label="Estado" className={classes.autocomplete} />
            )}
            value={statusSel}
            onChange={(event, newValue) => setStatusSel(newValue)}
          />
        </FormControl>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleFilter}
        >
          Filtrar
        </Button>
      </div>
    </Paper>
  );
};

export default FilterPanel;