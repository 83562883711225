import React, { useState, useRef } from 'react';
import {
  Paper,
  TextField,
  Button,
  Typography,
  makeStyles,
  Grid,
  Box,
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
  TablePagination,
  CircularProgress,
  Snackbar,
  Checkbox,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@material-ui/core';
import {
  FormatBold,
  FormatItalic,
  FormatSize,
  Code as CodeIcon,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { invokeApig } from '../lib/awsLib';
import ImageUploader from '../components/ImageUploader';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  editor: {
    minHeight: '300px',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    '&:focus': {
      outline: 'none',
    },
  },
  htmlEditor: {
    width: '100%',
    minHeight: '400px',
    fontFamily: 'monospace',
    padding: theme.spacing(1),
  },
  subject: {
    marginBottom: theme.spacing(2),
  },
  recipientsContainer: {
    marginTop: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const MassEmailNotification = () => {
  const classes = useStyles();
  const editorRef = useRef(null);
  
  // Estados
  const [subject, setSubject] = useState('');
  const [showHtmlDialog, setShowHtmlDialog] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ 
    open: false, 
    message: '', 
    severity: 'success' 
  });

  React.useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML = htmlContent;
    }
  }, []);

  // Funciones del editor
  const execCommand = (command, value = null) => {
    document.execCommand(command, false, value);
    // Solo actualizamos el estado, sin modificar el innerHTML
    if (editorRef.current) {
      setHtmlContent(editorRef.current.innerHTML);
    }
  };

  const handleEditorChange = () => {
    if (editorRef.current) {
      setHtmlContent(editorRef.current.innerHTML);
    }
  };

  const handleHtmlDialogSave = () => {
    if (editorRef.current) {
      editorRef.current.innerHTML = htmlContent;
      editorRef.current.focus();
    }
    setShowHtmlDialog(false);
  };

  const handleFontSize = () => {
    const size = prompt('Ingrese el tamaño de fuente (1-7):', '3');
    if (size && !isNaN(size) && size >= 1 && size <= 7) {
      execCommand('fontSize', size);
    }
  };

  // Función para añadir imágenes
  const addImage = (url) => {
    if (editorRef.current) {
      const img = `<img src="${url}" style="max-width: 100%; height: auto;" />`;
      document.execCommand('insertHTML', false, img);
      setHtmlContent(editorRef.current.innerHTML);
    }
  };

  // Manejadores de eventos para recipientes
  const handleGroupChange = async (newGroup) => {
    setSelectedGroup(newGroup);
    setSelectedRecipients([]);
    setRecipients([]);
    
    if (newGroup) {
      setLoading(true);
      try {
        const response = await invokeApig({
          path: "/account/emails/find",
          method: "POST",
          body: { type: newGroup }
        });
        
        if (response.code === 0 && response.payload) {
          setRecipients(response.payload);
        } else {
          throw new Error(response.message || 'Error al obtener los destinatarios');
        }
      } catch (error) {
        console.error('Error fetching recipients:', error);
        setNotification({
          open: true,
          message: 'Error al cargar los destinatarios',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSelectAll = (event) => {
    setSelectedRecipients(event.target.checked 
      ? recipients.map(recipient => recipient.id)
      : []
    );
  };

  const handleSelectRecipient = (id) => {
    setSelectedRecipients(prev => 
      prev.includes(id)
        ? prev.filter(recipientId => recipientId !== id)
        : [...prev, id]
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSendEmails = async () => {
    if (!subject || !htmlContent || selectedRecipients.length === 0) {
      setNotification({
        open: true,
        message: 'Por favor complete todos los campos requeridos',
        severity: 'warning',
      });
      return;
    }

    setLoading(true);
    try {
      const selectedEmails = recipients
        .filter(recipient => selectedRecipients.includes(recipient.id))
        .map(recipient => recipient.email);

      await invokeApig({
        path: "/account/emails/send",
        method: "POST",
        body: {
          subject,
          content: htmlContent,
          recipients: selectedEmails,
        }
      });

      setNotification({
        open: true,
        message: 'Correos enviados exitosamente',
        severity: 'success',
      });
      
      // Limpiar formulario
      setSubject('');
      if (editorRef.current) {
        editorRef.current.innerHTML = '';
        setHtmlContent('');
      }
      setSelectedRecipients([]);
      setSelectedGroup('');
      setRecipients([]);
    } catch (error) {
      console.error('Error sending emails:', error);
      setNotification({
        open: true,
        message: 'Error al enviar los correos',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification(prev => ({ ...prev, open: false }));
  };

  // Datos estáticos
  const recipientGroups = [
    { value: 'invoice', label: 'Facturación' },
    { value: 'sale', label: 'Comercial' },
    { value: 'owner', label: 'Administrador' },
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TextField
          fullWidth
          label="Asunto"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className={classes.subject}
          variant="outlined"
        />

        <div>
          <div className={classes.toolbar}>
            <Tooltip title="Negrita">
              <IconButton onClick={() => execCommand('bold')}>
                <FormatBold />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cursiva">
              <IconButton onClick={() => execCommand('italic')}>
                <FormatItalic />
              </IconButton>
            </Tooltip>
            <Tooltip title="Tamaño de fuente">
              <IconButton onClick={handleFontSize}>
                <FormatSize />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver/Editar HTML">
              <IconButton onClick={() => setShowHtmlDialog(true)}>
                <CodeIcon />
              </IconButton>
            </Tooltip>
          </div>
          <ImageUploader onImageUrlCopy={addImage} />
          <div
            ref={editorRef}
            className={classes.editor}
            contentEditable
            onInput={handleEditorChange}
            suppressContentEditableWarning={true}
          />
        </div>

        <Dialog
          open={showHtmlDialog}
          onClose={() => setShowHtmlDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Editar HTML</DialogTitle>
          <DialogContent>
            <textarea
              className={classes.htmlEditor}
              value={htmlContent}
              onChange={(e) => setHtmlContent(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowHtmlDialog(false)}>
              Cancelar
            </Button>
            <Button onClick={handleHtmlDialogSave} color="primary" variant="contained">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Seleccionar Grupo de Destinatarios
        </Typography>

        <Grid container spacing={2}>
          {recipientGroups.map((group) => (
            <Grid item key={group.value}>
              <Button
                variant={selectedGroup === group.value ? "contained" : "outlined"}
                color="primary"
                onClick={() => handleGroupChange(group.value)}
              >
                {group.label}
              </Button>
            </Grid>
          ))}
        </Grid>

        {loading ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : selectedGroup && (
          <div className={classes.recipientsContainer}>
            <TableContainer>
              <MuiTable>
                <TableHead>
                  <MuiTableRow>
                    <MuiTableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selectedRecipients.length > 0 &&
                          selectedRecipients.length < recipients.length
                        }
                        checked={
                          recipients.length > 0 &&
                          selectedRecipients.length === recipients.length
                        }
                        onChange={handleSelectAll}
                      />
                    </MuiTableCell>
                    <MuiTableCell>Nombre</MuiTableCell>
                    <MuiTableCell>Email</MuiTableCell>
                  </MuiTableRow>
                </TableHead>
                <TableBody>
                  {recipients
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((recipient) => (
                      <MuiTableRow key={recipient.id} hover>
                        <MuiTableCell padding="checkbox">
                          <Checkbox
                            checked={selectedRecipients.includes(recipient.id)}
                            onChange={() => handleSelectRecipient(recipient.id)}
                          />
                        </MuiTableCell>
                        <MuiTableCell>{recipient.name || 'Sin nombre'}</MuiTableCell>
                        <MuiTableCell>{recipient.email}</MuiTableCell>
                      </MuiTableRow>
                    ))}
                </TableBody>
              </MuiTable>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={recipients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>

      <Box className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendEmails}
          disabled={loading || selectedRecipients.length === 0}
        >
          {loading ? (
            <>
              <CircularProgress size={24} />
              &nbsp;Enviando...
            </>
          ) : (
            'Enviar'
          )}
        </Button>
      </Box>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MassEmailNotification;