// ImageUploader.js
import React, { useState } from 'react';
import { invokeApig } from '../lib/awsLib';
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Delete as DeleteIcon, FileCopy as CopyIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  uploadedImage: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  imagesList: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
  copyButton: {
    marginLeft: 'auto',
  },
  urlText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    wordBreak: 'break-all',
    flex: 1,
  },
}));

const ImageUploader = ({ onImageUrlCopy }) => {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Extraer solo la parte base64 del resultado, removiendo el prefijo "data:image/..."
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    // Validar el tamaño del archivo (por ejemplo, máximo 5MB)
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      setNotification({
        open: true,
        message: 'El archivo es demasiado grande. El tamaño máximo es 5MB.',
        severity: 'error'
      });
      return;
    }

    // Validar el tipo de archivo
    if (!file.type.startsWith('image/')) {
      setNotification({
        open: true,
        message: 'Por favor, seleccione un archivo de imagen válido.',
        severity: 'error'
      });
      return;
    }

    setUploading(true);
    try {
      // Convertir imagen a base64
      const base64Image = await convertToBase64(file);
      
      // Enviar a la API
      const response = await invokeApig({
        path: "/account/images/upload",
        method: "POST",
        body: {
          fileName: file.name,
          contentType: file.type,
          image: base64Image
        }
      });

      // Asumiendo que la API retorna la URL de la imagen en response.payload.url
      if (response.code === 0 && response.payload?.url) {
        setUploadedImages(prev => [...prev, {
          fileName: response.payload.fileName,
          url: response.payload.url,
        }]);

        setNotification({
          open: true,
          message: 'Imagen subida exitosamente',
          severity: 'success'
        });
      } else {
        throw new Error(response.message || 'Error al subir la imagen');
      }

    } catch (error) {
      console.error('Error uploading image:', error);
      setNotification({
        open: true,
        message: 'Error al subir la imagen: ' + (error.message || 'Error desconocido'),
        severity: 'error'
      });
    } finally {
      setUploading(false);
      // Limpiar el input file
      event.target.value = '';
    }
  };

  const handleDelete = async (fileName) => {
    try {
      await invokeApig({
        path: "/account/images/delete",
        method: "POST",
        body: {
          fileName: fileName
        }
      });

      setUploadedImages(prev => prev.filter(img => img.fileName !== fileName));
      setNotification({
        open: true,
        message: 'Imagen eliminada exitosamente',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting image:', error);
      setNotification({
        open: true,
        message: 'Error al eliminar la imagen',
        severity: 'error'
      });
    }
  };

  const handleCopyUrl = (url) => {
    onImageUrlCopy(url);
    setNotification({
      open: true,
      message: 'URL de imagen copiada al editor',
      severity: 'success'
    });
  };

  const handlePreview = (url) => {
    setPreviewUrl(url);
    setOpenPreview(true);
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <div className={classes.root}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-upload-button"
        type="file"
        onChange={handleFileSelect}
      />
      <label htmlFor="image-upload-button">
        <Button
          variant="contained"
          component="span"
          color="primary"
          disabled={uploading}
        >
          {uploading ? (
            <>
              <CircularProgress size={24} />
              &nbsp;Subiendo...
            </>
          ) : (
            'Subir Imagen'
          )}
        </Button>
      </label>

      {uploadedImages.length > 0 && (
        <div className={classes.imagesList}>
          <Typography variant="subtitle1" gutterBottom>
            Imágenes subidas:
          </Typography>
          {uploadedImages.map(({ fileName, url }) => (
            <div key={fileName} className={classes.uploadedImage}>
              <img
                src={url}
                alt="thumbnail"
                style={{ width: 30, height: 30, cursor: 'pointer' }}
                onClick={() => handlePreview(url)}
              />
              <Typography className={classes.urlText} variant="body2">
                {fileName}
              </Typography>
              <IconButton 
                className={classes.copyButton}
                onClick={() => handleCopyUrl(url)}
                size="small"
                title="Copiar al editor"
              >
                <CopyIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDelete(fileName)}
                size="small"
                color="secondary"
                title="Eliminar imagen"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </div>
      )}

      <Dialog 
        open={openPreview} 
        onClose={() => setOpenPreview(false)}
        maxWidth="md"
      >
        <DialogTitle>Vista previa de imagen</DialogTitle>
        <DialogContent>
          <img 
            src={previewUrl} 
            alt="preview" 
            style={{ maxWidth: '100%', maxHeight: '70vh' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPreview(false)} color="primary">
            Cerrar
          </Button>
          <Button 
            onClick={() => handleCopyUrl(previewUrl)} 
            color="primary" 
            variant="contained"
          >
            Copiar URL
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ImageUploader;