import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DataGrid } from '@material-ui/data-grid';
import { invokeApig } from '../lib/awsLib';
import NeokodeButton from '../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
  },
  gridMaxMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  searchForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  userInfoCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginTop: theme.spacing(2),
  },
  searchResults: {
    marginTop: theme.spacing(2),
    height: 400,
    width: '100%',
  },
  companySearchResults: {
    marginTop: theme.spacing(2),
    height: 300,
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  dialogContent: {
    minWidth: 400,
  },
  companySearchContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  confirmationContent: {
    padding: theme.spacing(2),
  }
}));

function ChangeUsersPage() {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingCompanies, setIsSearchingCompanies] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companySearchTerm, setCompanySearchTerm] = useState('');
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const userColumns = [
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'name', headerName: 'Nombre', flex: 1 },
    { field: 'lastName', headerName: 'Apellido', flex: 1 },
    { field: 'ownerName', headerName: 'Empresa Actual', flex: 1 },
  ];

  const companyColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Nombre', flex: 1 },
    { field: 'status', headerName: 'Estado', flex: 1 },
  ];

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchTerm) {
      setError('Por favor ingresa un término de búsqueda');
      return;
    }

    try {
      setIsSearching(true);
      setError('');
      setUsers([]);
      setSelectedUser(null);

      const response = await invokeApig({
        path: "/account/user/find",
        method: "POST",
        body: {
          searchTerm
        }
      });

      if (response.code === 0 && response.results) {
        if (response.results.length === 0) {
          setError('No se encontraron usuarios');
        } else {
          setUsers(response.results.map((user, index) => ({
            ...user,
            id: index
          })));
        }
      } else {
        setError('Error al buscar usuarios');
      }
    } catch (err) {
      console.error('Error searching users:', err);
      setError('Error al buscar usuarios. Por favor intenta nuevamente.');
    } finally {
      setIsSearching(false);
    }
  };

  const handleUserSelect = (params) => {
    const user = users.find(u => u.id === params.row.id);
    if (user) {
      setSelectedUser(user);
      setOpenUserDialog(true);
      setCompanySearchTerm('');
      setCompanies([]);
      setSelectedCompany(null);
    }
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    setError('');
    setSelectedCompany(null);
    setCompanySearchTerm('');
    setCompanies([]);
  };

  const handleCompanySearch = async (e) => {
    e.preventDefault();
    if (!companySearchTerm) {
      setError('Por favor ingresa un término de búsqueda para la empresa');
      return;
    }

    try {
      setIsSearchingCompanies(true);
      setError('');
      setCompanies([]);
      
      const response = await invokeApig({
        path: "/account/find",
        method: "POST",
        body: {
          searchTerm: companySearchTerm
        }
      });

      if (response.code === 200) {
        if (!response.payload.owners || response.payload.owners.length === 0) {
          setError('No se encontraron empresas');
        } else {
          setCompanies(response.payload.owners);
          setOpenCompanyDialog(true);
        }
      } else {
        setError('Error al buscar empresas');
      }
    } catch (err) {
      console.error('Error searching companies:', err);
      setError('Error al buscar empresas. Por favor intenta nuevamente.');
    } finally {
      setIsSearchingCompanies(false);
    }
  };

  const handleCompanySelect = (params) => {
    const company = companies.find(c => c.id === params.row.id);
    if (company) {
      setSelectedCompany(company);
      setOpenCompanyDialog(false);
      setOpenConfirmDialog(true);
    }
  };

  const handleCloseCompanyDialog = () => {
    setOpenCompanyDialog(false);
    setError('');
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedCompany(null);
    setError('');
  };

  const handleUpdateCompany = async () => {
    if (!selectedUser?.email || !selectedCompany?.id) {
      setError('Debe seleccionar un usuario y una empresa');
      return;
    }

    try {
      setIsLoading(true);
      setError('');

      const response = await invokeApig({
        path: "/account/update-company",
        method: "PUT",
        body: {
          email: selectedUser.email,
          owner: selectedCompany.id
        }
      });

      if (response.code === 0) {
        setSuccess('La empresa del usuario ha sido actualizada exitosamente');
        setOpenSnackbar(true);
        // Reset form
        setSelectedUser(null);
        setSelectedCompany(null);
        setSearchTerm('');
        setCompanySearchTerm('');
        setCompanies([]);
        setOpenUserDialog(false);
        setOpenConfirmDialog(false);
        setUsers([]);
      } else {
        setError(response.message || 'Error al actualizar la empresa del usuario');
      }
    } catch (err) {
      console.error('Error updating company:', err);
      setError('Error al actualizar la empresa. Por favor intenta nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Cambio de Empresa de Usuario
          </Typography>

          {/* Búsqueda de Usuario */}
          <form onSubmit={handleSearch} className={classes.searchForm}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  label="Buscar usuario"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  helperText="Ingresa el email o nombre del usuario"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  disabled={isSearching}
                >
                  {isSearching ? <CircularProgress size={24} /> : 'Buscar'}
                </Button>
              </Grid>
            </Grid>
          </form>

          {/* Lista de Usuarios */}
          {users.length > 0 && (
            <div className={classes.searchResults}>
              <DataGrid
                rows={users}
                columns={userColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onRowClick={handleUserSelect}
                disableSelectionOnClick
                loading={isSearching}
              />
            </div>
          )}

          {/* Dialog para mostrar el detalle del usuario y búsqueda de empresa */}
          <Dialog 
            open={openUserDialog} 
            onClose={handleCloseUserDialog}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Cambio de Empresa para Usuario
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {selectedUser && (
                <Card className={classes.userInfoCard}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Información del Usuario
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography><strong>Email:</strong> {selectedUser.email}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography><strong>Nombre:</strong> {selectedUser.name} {selectedUser.lastName}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography><strong>Empresa Actual:</strong> {selectedUser.ownerName || 'Sin empresa asignada'}</Typography>
                      </Grid>
                    </Grid>

                    {/* Búsqueda de Empresa */}
                    <form onSubmit={handleCompanySearch} className={classes.companySearchContainer}>
                      <TextField
                        label="Buscar empresa"
                        value={companySearchTerm}
                        onChange={(e) => setCompanySearchTerm(e.target.value)}
                        fullWidth
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCompanySearch}
                        disabled={isSearchingCompanies}
                      >
                        {isSearchingCompanies ? <CircularProgress size={24} /> : 'Buscar'}
                      </Button>
                    </form>

                    {selectedCompany && (
                      <Alert severity="info" style={{ marginTop: 16 }}>
                        Empresa seleccionada: {selectedCompany.name}
                      </Alert>
                    )}

                    {error && <Alert severity="error" style={{ marginTop: 16 }}>{error}</Alert>}

                    <div className={classes.buttonsContainer}>
                      <Button
                        variant="contained"
                        onClick={handleCloseUserDialog}
                        className={classes.button}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              )}
            </DialogContent>
          </Dialog>

          {/* Dialog para mostrar la lista de empresas */}
          <Dialog
            open={openCompanyDialog}
            onClose={handleCloseCompanyDialog}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Seleccionar Empresa
            </DialogTitle>
            <DialogContent>
              <div className={classes.companySearchResults}>
                <DataGrid
                  rows={companies}
                  columns={companyColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  onRowClick={handleCompanySelect}
                  disableSelectionOnClick
                  loading={isSearchingCompanies}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCompanyDialog} color="primary">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog de confirmación */}
          <Dialog
            open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              Confirmar Cambio de Empresa
            </DialogTitle>
            <DialogContent className={classes.confirmationContent}>
              <Typography gutterBottom>
                ¿Estás seguro que deseas cambiar la empresa del usuario?
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography><strong>Usuario:</strong> {selectedUser?.name} {selectedUser?.lastName}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography><strong>Email:</strong> {selectedUser?.email}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography><strong>Empresa Actual:</strong> {selectedUser?.ownerName || 'Sin empresa asignada'}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography><strong>Nueva Empresa:</strong> {selectedCompany?.name}</Typography>
                </Grid>
              </Grid>
              {error && <Alert severity="error" style={{ marginTop: 16 }}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog} color="primary">
                Cancelar
              </Button>
              <NeokodeButton
                onClick={handleUpdateCompany}
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                {isLoading ? <CircularProgress size={24} /> : 'Confirmar Cambio'}
              </NeokodeButton>
            </DialogActions>
          </Dialog>

          <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity="success">
              {success}
            </Alert>
          </Snackbar>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ChangeUsersPage;