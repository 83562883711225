import React, { useState } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Typography, Grid, CircularProgress, Input, InputAdornment, Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import NeokodeButton from '../../components/NeokodeButton';
import { Link as RouterLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import user_access from '../../data/user_access';
import { invokeApig } from '../../lib/awsLib';
import { validateEmail, validateName, validateText, validateTextNumber, validateTextNumberAndSpace, validateUserEmail } from '../../lib/validate';

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  accessTitle: {
    marginTop: '40px',
  },
  buttonGoBack: {
    marginTop: '15px',
  },
  labelSwitch: {
    marginLeft: 0,
    padding: '6px 0 7px',
  }
}));

function CustomerForm(props) {
  const classes = useStyles();
  const access = props.params.access;
  const hasId = !!props.customer && !!props.customer.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [customer, setCustomer] = useState(
    hasId ?
    {
      id: props.customer.id,
      account_aws: props.customer.account_aws,
      name: props.customer.name,
      email: props.customer.email.split('@')[0],
      contact: props.customer.contact,
      status: props.customer.status,
      registerPayment: props.customer.registerPayment ? props.customer.registerPayment : true,
    }
    :
    {
      id: '',
      account_aws: '',
      name: '',
      email: '',
      contact: '',
      status: '',
      registerPayment: true,
    }
  );

  const createCustomer = (customer) => {
    return invokeApig({
      path: "/account",
      method: "POST",
      body: customer,
    });
  };

  const updateCustomer = (customer) => {
    return invokeApig({
      path: `/account`,
      method: "PUT",
      body: customer
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCustomer({ ...customer, [name]: value });
  };

  const handleChangeChecked = (event) => {
    const { name, checked } = event.target;
    setCustomer({ ...customer, [name]: checked });
  };

  const validate = () => {
    let message = undefined;
    if (!validateTextNumber(customer.id)) {
      message = 'Debe ingresar el identificador del cliente correctamente';
    } else {
      if (!validateTextNumberAndSpace(customer.name)) {
        message = 'Debe ingresar el nombre del cliente correctamente';
      } else {
        if (!validateUserEmail(customer.email)) {
          message = 'Debe ingresar el email del cliente correctamente';
        } else {
          if (!validateEmail(customer.contact)) {
            message = 'Debe ingresar el email de contacto correctamente';
          }
        }
      }
    }
    return message;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const errorMsg = validate();
    if (errorMsg) {
      setMessage(errorMsg);
      setIsError(true);
      setIsLoading(false);
      return;
    }
    if (hasId) { //editar
      const data = {
        id: customer.id,
        name: customer.name,
        contact: customer.contact,
        registerPayment: customer.registerPayment,
      };
      const response = await updateCustomer(data);
      if (response.code !== 200) {
        let msg = 'Error al modificar el cliente, por favor revisa los datos e intenta nuevamente';
        setMessage(msg);
        setIsError(true);
      } else {
        setMessage('El cliente se ha modificado correctamente');
        setIsSuccess(true);
      }
    } else { //crear
      const data = {
        account: customer.id,
        name: customer.name,
        email: customer.email,
        contact: customer.contact,
        registerPayment: customer.registerPayment,
      };
      const response = await createCustomer(data);
      if (response.status !== 0) {
        let msg = 'Error al crear el cliente, por favor revisa los datos e intenta nuevamente';
        setMessage(msg);
        setIsError(true);
      } else {
        setMessage('El cliente se ha creado correctamente');
        setIsSuccess(true);
      }
    }
    setIsLoading(false);
  };

  const handleGoBack = (event) => {
    event.preventDefault();
    if (props.onFinish) {
      props.onFinish(false);
    } else {
      props.history.push('/customers');
    }
  }

  if (isSuccess) {
    return (
      <>
        <Alert severity="success">{message}</Alert>
        <NeokodeButton variant="contained" component={RouterLink} to="/customers" className={classes.buttonGoBack}>Volver</NeokodeButton>
      </>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField label="RUT" name="id" value={customer.id} onChange={handleChange} required fullWidth className={classes.formControl} InputProps={{ readOnly: isLoading, }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Nombre" name="name" value={customer.name} onChange={handleChange} required fullWidth className={classes.formControl} InputProps={{ readOnly: isLoading, }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required className={classes.formControl}>
              <InputLabel htmlFor="email" shrink={!!customer.email}>Email</InputLabel>
              <Input id="email" name="email" value={customer.email} onChange={handleChange} autoComplete='new-password'
                endAdornment={<InputAdornment position="end">@neokode.cl</InputAdornment>}
                readOnly={hasId}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Email contacto" name="contact" value={customer.contact} onChange={handleChange} required fullWidth className={classes.formControl} InputProps={{ readOnly: isLoading, }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      id="registerPayment"
                      name="registerPayment"
                      checked={customer.registerPayment}
                      onChange={handleChangeChecked}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="Pago automático"
                  labelPlacement="start"
                  className={classes.labelSwitch}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          {
            hasId &&
            <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Estado</InputLabel>
                  <Select name='status' value={customer.status} onChange={handleChange} inputProps={{ readOnly: isLoading, }}>
                    <MenuItem value="Activo">Activo</MenuItem>
                    <MenuItem value="Inactivo">Inactivo</MenuItem>
                  </Select>
                </FormControl>
             </Grid>
            </>
          }
        </Grid>
        {
          isError &&
          <Alert severity="error">
            {message}
          </Alert>
        }
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              type="button"
              variant="contained"
              className={classes.button}
              onClick={handleGoBack}
              disabled={isLoading}
            >
              Volver
            </Button>
          </Grid>
          <Grid item>
            <NeokodeButton
              type="submit"
              variant="contained"
              className={classes.button}
              disabled={isLoading}
            >
              {
                isLoading ?
                <CircularProgress />
                :
                ( props.customer ? 'Editar Cliente' : 'Agregar Cliente' )
              }
            </NeokodeButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default CustomerForm;