import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Typography, Grid, CircularProgress, TextareaAutosize, Chip, Snackbar } from '@material-ui/core';
import NeokodeButton from '../../components/NeokodeButton';
import { Link as RouterLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { invokeApig } from '../../lib/awsLib';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import CommentsPanel from './CommentsPanel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  closeButton: {
    margin: theme.spacing(1),
    backgroundColor: '#2196f3',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2086e3',
    },
  },
  title: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  accessTitle: {
    marginTop: '40px',
  },
  buttonGoBack: {
    marginTop: '15px',
  },
  autocomplete: {
    '& .MuiInputBase-input': {
      width: '100%',
    }
  },
  message: {
    textAlign: 'left',
    marginTop: '10px',
    backgroundColor: '#e0f7fa',
  },
  buttons: {
    margin: theme.spacing(1),
  },
  green: {
    backgroundColor: '#00b31f',
    color: '#ffffff',
  },
  red: {
    backgroundColor: '#f44336',
    color: '#ffffff',
  },
  yellow: {
    backgroundColor: '#ff9800',
    color: '#ffffff',
  },
  blue: {
    backgroundColor: '#2196f3',
    color: '#ffffff',
  },
}));

function TicketForm(props) {
  const classes = useStyles();
  const readOnly = props.ticket ? true : false;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [ticket, setTicket] = useState({
    account: {value: '', label: ''},
    cause: {value: '', label: ''},
    username: '',
    useremail: '',
    subject: '',
    message: '',
  });
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [customers, setCustomers] = useState([{value: '', label: 'Seleccionar cliente'}]);
  const [isResponse, setIsResponse] = useState(false);
  const [reply, setReply] = useState('');
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    if (!readOnly) {
      getCustomers();
    }
  }, []);

  const getCustomers = async () => {
    try {
      setIsLoadingCustomer(true);
      setCustomers([{value: '', label: ''}]);
      let lastKey = undefined;
      do {
        const res = await invokeApig({
          path: "/account/find",
          method: "POST",
          body: {
            "LastEvaluatedKey": lastKey ? lastKey : undefined,
            "limit": 50
          }
        });
        if (res.code === 200) {
          const newCustomers = [];
          res.payload.owners.map((owner, index) => {
            newCustomers.push({
              value: owner.id,
              label: owner.name
            });
          });
          setCustomers([...customers, ...newCustomers]);
          setIsLoadingCustomer(false);
          lastKey = res.payload.LastEvaluatedKey;
        } else {
          break;
        }
      } while (lastKey !== null);
    } catch (error) {
      console.error('Error in getCustomers:', error);
    }
    setIsLoadingCustomer(false);
  }

  const createTicket = (ticket) => {
    return invokeApig({
      path: "/support",
      method: "POST",
      body: ticket
    });
  };

  const replyTicket = (ticket) => {
    return invokeApig({
      path: `/support/reply`,
      method: "POST",
      body: ticket
    });
  };

  const closeTicket = (ticket) => {
    return invokeApig({
      path: `/support/close`,
      method: "POST",
      body: ticket
    });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTicket({ ...ticket, [name]: value });
  };
  const handleChangeValue = (name, value) => {
    setTicket({ ...ticket, [name]: value });
  };

  const validate = () => {
    return (
      ticket.account.value && ticket.account.value.length > 0 &&
      ticket.username.length > 0 &&
      ticket.useremail.length > 0 &&
      ticket.cause.length > 3 &&
      ticket.subject.length > 3 &&
      ticket.message.length > 3
    );
  }

  const handleSubmitNew = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const isValid = validate();
      if (!isValid) {
        setMessage('Debes ingresar todos los campos solicitados');
        setIsError(true);
        return;
      }
      const new_ticket = {
        account: ticket.account.value,
        username: ticket.username,
        useremail: ticket.useremail,
        cause: ticket.cause,
        subject: ticket.subject,
        message: ticket.message,
      };
      const response = await createTicket(new_ticket);
      if (response.code !== 200) {
        let msg = 'Error al crear el ticket, por favor revisa los datos e intenta nuevamente';
        if (response.message) {
          msg = response.message;
        }
        setMessage(msg);
        setIsError(true);
      } else {
        setMessage('El ticket se ha creado correctamente');
        setIsSuccess(true);
      }
    } catch(exception) {
      console.log('Error al guardar ticket');
      setMessage('No pudimos crear el ticket, intenta nuevamente o contacta a Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  };

  const handleReplyTicket = () => {
    setIsResponse(true);
  };
  const handleCloseTicket = async () => {
    setIsClose(true);
  };
  const handleSaveReply = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      if (reply.length === 0) {
        setMessage('Debes ingresar una respuesta');
        setIsError(true);
        setIsLoading(false);
        return;
      }

      const new_reply = {
        account: props.ticket.ownerId,
        id: props.ticket.id,
        message: reply
      };
      const response = isClose ? await closeTicket(new_reply) : await replyTicket(new_reply);
      if (response.code !== 200) {
        let msg = `Error al ${isClose ? 'cerrar' : 'responder'} el ticket, intenta nuevamente o contacta a Soporte`;
        setMessage(msg);
        setIsError(true);
      } else {
        setMessage(`El ticket ha sido ${isClose ? 'cerrado' : 'respondido'} correctamente`);
        setIsSuccess(true);
      }
    } catch(exception) {
      console.log('Error al responder ticket');
      console.log(exception);
      setMessage(`No pudimos ${isClose ? 'cerrar' : 'responder'} el ticket, intenta nuevamente o contacta a Soporte`);
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleGoBack = (event) => {
    event.preventDefault();
    if (props.onFinish) {
      props.onFinish(false);
    } else {
      props.history.push('/tickets');
    }
  }

  if (isSuccess) {
    return (
      <>
        <Alert severity="success">{message}</Alert>
        <NeokodeButton variant="contained" component={RouterLink} to="/tickets" className={classes.buttonGoBack}>Ver los tickets</NeokodeButton>
      </>
    );
  }

  const getColorCause = (cause) => {
    let res = undefined;
    switch (cause) {
      case 'Error': {
        res = classes.red;
        break;
      }
      case 'Sugerencia': {
        res = classes.blue;
        break;
      }
      case 'Consulta': {
        res = classes.yellow;
        break;
      }
    }
    return res;
  }

  const getColorState = (state) => {
    let res = undefined;
    switch(state) {
      case 'Sin asignar':
        res = classes.yellow;
        break;
      case 'Resuelto':
        res = classes.green;
        break;
      case 'En progreso':
        res = classes.blue;
        break;
      default:
        res = undefined;
    }
    return res;
  }

  const getResponseTime = (creationDate, closeDate) => {
    const diff = moment.duration(moment(closeDate).diff(moment(creationDate)));
    const days = diff.days() + diff.months() * 30;
    const label = `${days} d ${diff.hours()} h ${diff.minutes()} m`;
    if (days > 3) {
      return (
        <Chip
          size="small"
          label={label}
          className={classes.red}
        />
      );
    }
    if (days > 1) {
      return (
        <Chip
          size="small"
          label={label}
          className={classes.yellow}
        />
      );
    }
    return (
      <Chip
        size="small"
        label={label}
        className={classes.blue}
      />
    );
  };

  const renderNew = () => (
    <form onSubmit={handleSubmitNew}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl}>
            {
              isLoadingCustomer ?
              <CircularProgress />
              :
              <>
                <Autocomplete
                  options={customers}
                  getOptionLabel={(option) => option.label || ''}
                  getOptionSelected={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField {...params} label="Cliente" className={classes.autocomplete} />
                  )}
                  value={ticket.account}
                  onChange={(event, newValue) => handleChangeValue('account', newValue)}
                />
              </>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Nombre solicitando" name="username" value={ticket.username} onChange={handleChange} required fullWidth className={classes.formControl} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Email solicitante" name="useremail" value={ticket.useremail} onChange={handleChange} required fullWidth className={classes.formControl} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Causa</InputLabel>
            <Select name='cause' value={ticket.cause} onChange={handleChange}>
              <MenuItem value="">Selecciona...</MenuItem>
              <MenuItem value="Consulta">Consulta</MenuItem>
              <MenuItem value="Sugerencia">Sugerencia</MenuItem>
              <MenuItem value="Error">Error</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Asunto" name="subject" value={ticket.subject} onChange={handleChange} required fullWidth className={classes.formControl} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Mensaje"
            name="message"
            value={ticket.message}
            onChange={handleChange}
            required fullWidth
            className={classes.formControl}
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: { style: { resize: 'both' } },
            }}
            multiline minRows={4}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
      {
        isError &&
        <Alert severity="error">
          {message}
        </Alert>
      }
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleGoBack}
            disabled={isLoading}
          >
            Volver
          </Button>
        </Grid>
        <Grid item>
          <NeokodeButton
            type="submit"
            variant="contained"
            className={classes.button}
            disabled={isLoading}
          >
            {
              isLoading ?
              <CircularProgress />
              :
              'Agregar Ticket'
            }
          </NeokodeButton>
        </Grid>
      </Grid>
    </form>
  );

  const renderTicket = () => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={2}>
          <b>Cliente</b>
        </Grid>
        <Grid item xs={8} sm={4}>
          {props.ticket.ownerId}
        </Grid>
        <Grid item xs={4} sm={2}>
          <b>Usuario</b>
        </Grid>
        <Grid item xs={8} sm={4}>
          {props.ticket.username} &lt;{props.ticket.useremail}&gt;
        </Grid>
        <Grid item xs={4} sm={2}>
          <b>Causa</b>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Chip
            size="small"
            label={props.ticket.cause}
            className={getColorCause(props.ticket.cause)}
          />
          /
          <Chip
            size="small"
            label={props.ticket.status}
            className={getColorState(props.ticket.status)}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <b>Asunto</b>
        </Grid>
        <Grid item xs={8} sm={4}>
          {props.ticket.subject}
        </Grid>
        <Grid item xs={4} sm={2}>
          <b>Fecha</b>
        </Grid>
        <Grid item xs={8} sm={4}>
          { moment(props.ticket.creationDate).utcOffset(props.ticket.creationDateZone).format('DD-MM-YYYY HH:mm') }
        </Grid>
        <Grid item xs={4} sm={2}>
          <b>Cierre</b>
        </Grid>
        <Grid item xs={8} sm={4}>
          { !!props.ticket.closeDate && moment(props.ticket.closeDate).utcOffset(props.ticket.closeDateZone).format('DD-MM-YYYY HH:mm') }
        </Grid>
        <Grid item xs={4} sm={2}>
          <b>Tiempo</b>
        </Grid>
        <Grid item xs={8} sm={4}>
          {getResponseTime(props.ticket.creationDate, props.ticket.closeDate)}
        </Grid>
        <Grid item xs={4} sm={2}>
          <b>Responsable</b>
        </Grid>
        <Grid item xs={8} sm={4}>
          {props.ticket.assignedname ? <>{props.ticket.assignedname} &lt;{props.ticket.assignedemail}&gt;</> : ''}
        </Grid>
        <Grid item xs={4} sm={2}>
          <b>Mensaje</b>
        </Grid>
        <Grid item xs={8} sm={10} className={classes.message}>
          {props.ticket.message}
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" className={classes.buttons}>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleGoBack}
            disabled={isLoading}
          >
            Volver
          </Button>
        </Grid>
        {
          readOnly ?
          <>
            {
              (props.ticket.status !== 'Resuelto') &&
              <>
                {
                  (isResponse || isClose) ?
                  <NeokodeButton
                    type="button"
                    variant="contained"
                    className={classes.button}
                    disabled={isLoading}
                    onClick={handleSaveReply}
                  >
                    {
                      isLoading ?
                      <CircularProgress />
                      :
                      isResponse ? 'Enviar respuesta' : 'Cerrar ticket'
                    }
                  </NeokodeButton>
                  :
                  <>
                    <NeokodeButton
                      type="button"
                      variant="contained"
                      className={classes.button}
                      disabled={isLoading}
                      onClick={handleReplyTicket}
                    >
                      {
                        isLoading ?
                        <CircularProgress />
                        :
                        'Responder ticket'
                      }
                    </NeokodeButton>
                    <Button
                      type="button"
                      variant="contained"
                      className={classes.closeButton}
                      disabled={isLoading}
                      onClick={handleCloseTicket}
                    >
                      {
                        isLoading ?
                        <CircularProgress />
                        :
                        'Cerrar ticket'
                      }
                    </Button>
                  </>
                }
              </>
            }
          </>
          :
          <Grid item>
            <NeokodeButton
              type="submit"
              variant="contained"
              className={classes.button}
              disabled={isLoading}
            >
              {
                isLoading ?
                <CircularProgress />
                :
                'Agregar Ticket'
              }
            </NeokodeButton>
          </Grid>
        }
      </Grid>
      {
        isError &&
        <Alert severity="error">
          {message}
        </Alert>
      }
      {
        (isResponse || isClose) &&
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Ingresa tu respuesta"
              name="message"
              value={reply}
              onChange={(event) => setReply(event.target.value)}
              required fullWidth
              InputProps={{
                inputProps: { style: { resize: 'both' } },
              }}
              multiline minRows={4}
              style={{ width: '100%' }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      }
      {
        readOnly &&
        <>
          <Typography variant="h6" className={classes.accessTitle}>Comentarios</Typography>
          <CommentsPanel comments={props.ticket.comments} />

        </>
      }
    </>
  )

  return (
    readOnly ?
    renderTicket()
    :
    renderNew()
  );
}

export default TicketForm;