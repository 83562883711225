import { Auth } from 'aws-amplify';
import config from "../config";

export async function invokeApig({
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  body
}) {
  if (!await checkAuth()) {
    window.location.href = config.apps.admin;
  }

  body = body ? JSON.stringify(body) : body;

  const currentUser = await Auth.currentAuthenticatedUser();
  if (currentUser === null) {
    return false;
  }
  const userToken = await getUserToken();
  const url = config.apiGateway.URL + path;
  headers["Authorization"] = userToken;
  headers["content-type"] = "application/json";
  const results = await fetch(url, {
    method,
    headers,
    body
  });

  if (results.status !== 200) {
    throw new Error(await results.text());
  }

  return results.json();
}

async function checkAuth() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (err) {
    return false;
  }
}

async function getUserToken() {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();
  return idToken;
}

export async function getAccessToken() {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken().getJwtToken();
  return accessToken;
}
