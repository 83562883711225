import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import { invokeApig } from '../lib/awsLib';
import TicketForm from '../containers/support/TicketForm';
import NeokodeButton from '../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  gridMaxWidth: {
      [theme.breakpoints.up('md')]: {
          maxWidth: theme.breakpoints.values.md,
      },
  },
  paper: {
      padding: theme.spacing(2),
  },
  
}));

function TicketPage(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const { id } = useParams();
  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    getTicket();
  }, []);

  const getTicket = async() => {
    if (id) {
        try {
            setIsLoading(true);
            const response = await invokeApig({
                path: `/support/${id}`,
            });
            if (response.code === 200) {
              response.ticket.comments = response.comments;
              setTicket(response.ticket);
            } else {
              if (response.code === 404) {
                setMessage('El ticket no existe');
              } else {
                setMessage('No pudimos obtener los datos del ticket. Intanta nuevamente o comunícate con Soporte');
              }
              setIsError(true);
            }
        } catch (error) {
          setMessage('Error al obtener los datos del ticket. Intanta nuevamente o comunícate con Soporte');
          setIsError(true)
        }
    }
    setIsLoading(false);
  }

  const goTicketsList = () => {
    props.history.push('/tickets');
  }

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxWidth}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Datos del ticket
          </Typography>
          {
            isLoading ?
            <CircularProgress />
            :
            (
              isError ?
              <>
                <Alert severity="error">
                    {message}
                </Alert>
                <NeokodeButton
                  type="button"
                  variant="contained"
                  className={classes.button}
                  component={RouterLink}
                  to="/tickets"
                >
                  Volver al listado de tickets
                </NeokodeButton>
              </>
              :
              <TicketForm {...props} ticket={ticket} onFinish={goTicketsList} />
            )
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default TicketPage;
