import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, IconButton, Grid, Paper, TextField, Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DataGrid, esES } from '@material-ui/data-grid';
import * as Icons from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import NoRowsOverlay from '../components/NoRowsOverlay';
import QuickSearchToolbar from '../components/QuickSearchToolbar';
import { invokeApig } from '../lib/awsLib';
import NeokodeButton from '../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
  },
  gridMaxMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  breadcrumbContainer: {
    padding: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    margin: '0',
  },
  deleteButton: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#f44336',
    },
  },
  resetButton: {
    backgroundColor: '#2196f3',
    '&:hover': {
      backgroundColor: '#2196f3',
    },
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  },
  searchInput: {
    marginRight: '10px',
  },
  editIcon: {
    color: '#2196f3',
  },
  lockIcon: {
    color: '#ff9800',
  },
  deleteIcon: {
    color: '#f44336',
  },
  rootSearch: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textFieldSearch: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  buttonsDialogContainer: {
    padding: '10px 25px',
  },
  refreshIcon: {
    color: '#666',
    margin: '0 15px'
  },
  button: {
    margin: '15px',
  },
  confirmUserText: {
    textAlign: 'center',
  },
  green: {
    backgroundColor: '#00b31f',
    color: '#ffffff',
  },
  red: {
    backgroundColor: '#f44336',
    color: '#ffffff',
  },
  yellow: {
    backgroundColor: '#ff9800',
    color: '#ffffff',
  },
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function UsersPage(props) {
  const classes = useStyles();
  const [isGlobalError, setIsGlobalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState('');
  const [user, setUser] = useState({});
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isDeleteUser, setIsDeleteUser] = useState(false);
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      setIsLoading(true);
      setIsGlobalError(false);
      setUsers([]);
      setRows([]);
      setSearchText('');
      const response = await invokeApig({
        path: "/user/find",
        method: "POST",
        body: {
          "page": 0,
          "size": 100
        }
      });
      if (response.code === 0) {
        setUsers(response.results)
        setRows(response.results)
      } else {
        if (response.code === 403) {
          setMessage(response.message);
          setIsGlobalError(true);
        } else {
          setMessage('Error al obtener los usuarios. Intenta nuevamente o comunícate con Soporte');
          setIsGlobalError(true);
        }
      }
    } catch (error) {
      console.error('Error in getAccess:', error);
      setMessage('Error al obtener los usuarios. Intenta nuevamente o comunícate con Soporte');
      setIsGlobalError(true);
    }
    setIsLoading(false);
  }

  const resetPassword = async (username) => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/user/reset`,
        method: "POST",
        body: {
          username: username
        }
      });
      if (response.errorMessage) {
        setMessage(response.errorMessage);
        setIsError(true);
      } else {
        setMessageToast('Se ha enviado un correo al usuario con las instrucciones para cambiar la contraseña');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      console.error('Error in getAccess:', error);
      setMessage('Ocurrió un error al resetear la clave. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
    getUsers();
  }

  const deleteUser = async (username) => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/user/${username}`,
        method: "DELETE"
      });
      if (response.code !== 0 || response.errorMessage) {
        if (response.code === 403) {
          setMessage('No tienes permiso para eliminar usuarios');
        } else {
          setMessage(!!response.message ? response.message : (!!response.errorMessage ? response.errorMessage : 'Error al eliminar el usuario'));
        }
        setIsError(true);
      } else {
        setMessageToast('Se ha eliminado el usuario correctamente');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      console.error('Error in getAccess:', error);
      setMessage('Ocurrió un error al eliminar el usuario. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
    getUsers();
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = users.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const handleShowResetPassword = (username, user) => {
    setUsername(username);
    setUser(user);
    setIsResetPassword(true);
    setIsDeleteUser(false);
    setIsError(false);
    setMessage('');
    setOpenDialog(true);
  };
  const handleShowDelete = (username, user) => {
    setUsername(username);
    setUser(user);
    setIsResetPassword(false);
    setIsDeleteUser(true);
    setIsError(false);
    setMessage('');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirmAction = () => {
    if (isResetPassword) {
      resetPassword(username);
    }
    if (isDeleteUser) {
      deleteUser(username);
    }
  }

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const columns = [
    {
      field: 'username',
      headerName: 'ID usuario',
      width: 90,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Apellido',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'enabled',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => (
        params.value ?
        <Chip
          size="small"
          label="Activo"
          className={classes.green}
        />
        :
        <Chip
          size="small"
          label="Inactivo"
          className={classes.yellow}
        />
      )
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false ,
      renderCell: (params) => {
        return (
          <>
            <IconButton component={RouterLink} to={`/users/edit/${params.id}`} className={classes.editIcon}>
              <Icons.Edit />
            </IconButton>
            <IconButton onClick={() => handleShowResetPassword(params.id, params.row)} className={classes.lockIcon}>
              <Icons.Lock />
            </IconButton>
            <IconButton onClick={() => handleShowDelete(params.id, params.row)} className={classes.deleteIcon}>
              <Icons.Delete />
            </IconButton>
          </>
        ); 
      },
    }
  ];

  const showConfirmDialog = () => {
    return (
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          <p className={classes.confirmUserText}><b>Usuario</b>: {user.name} {user.lastName} &lt;{user.email}&gt;</p>
          <Alert severity="info">
            <p>{ isResetPassword && 'Se va a resetear la contraseña. Le llegará una correo electrónico con una contraseña temporal al usuario.' }</p>
            <p>{ isDeleteUser && 'Se va a eliminar el usuario y ya no tendrá acceso a la plataforma.' }</p>
            <p><b>¿Estás seguro que quieres continuar con la acción?</b></p>
          </Alert>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseDialog}
            disabled={isLoading}
          >
            No, cerra esta ventana
          </Button>
          <Button
            type="button"
            color={isDeleteUser ? "secondary" : "primary"}
            variant="contained"
            className={isDeleteUser ? classes.deleteButton : classes.resetButton}
            onClick={handleConfirmAction}
            disabled={isLoading}
          >
            Sí, confirmo
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderError = () => (
    <>
      <Alert severity="error">
          {message}
      </Alert>
      <NeokodeButton
        type="button"
        variant="contained"
        className={classes.button}
        component={RouterLink}
        to="/"
      >
        Volver al inicio
      </NeokodeButton>
    </>
  );

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Administración de Usuarios Manager
          </Typography>

          {
            isGlobalError ?
            renderError()
            :
            <>
              <div style={{ height: '690px', width: '100%' }}>
                <DataGrid
                  getRowId={(row) => row.username}
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  loading={isLoading}
                  components={{
                    NoRowsOverlay: NoRowsOverlay,
                    Toolbar: QuickSearchToolbar
                  }}
                  componentsProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(''),
                      onRefresh: () => getUsers(),
                      addButtonUrl: '/users/create',
                      addButtonText: 'Agregar Usuario',
                    },
                  }}
                  localeText={esES.props.MuiDataGrid.localeText}
                />
              </div>
              {showConfirmDialog()}
            </>
          }
        </Paper>
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default UsersPage;
