import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography, Avatar, Box, Paper } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
    welcome: {
        marginTop: '15px',
    },
    userCard: {
        backgroundColor: '#383838',
        color: '#fff',
        textAlign: 'center',
    },
    avatar: {
        backgroundColor: '#00b31f',
        width: theme.spacing(7),
        height: theme.spacing(7),
        margin: 'auto'
    },
    section: {
        margin: theme.spacing(3, 0),
        marginBottom: theme.spacing(10),
        width: 'inherit',
    },
    paper: {
        padding: theme.spacing(2),
    },
    gridMaxMd: {
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.lg,
        },
    },
    summary: {
        textAlign: 'center',
    },
    futureFunctions: {
        listStyle: 'none',
    },
}));

function HomePage(props) {
    const {access, isAutenticate} = props.params;
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState({});
    
    useEffect(() => {
        obtenerDatosUsuario();
    }, []);

    const obtenerDatosUsuario = async () => {
        try {
            const userData = await Auth.currentAuthenticatedUser();
            setUserInfo(userData);
        } catch (error) {
            console.error('Error al obtener los datos del usuario: ', error);
        }
    }

    const goToUrl = (url) => {
        window.location.href = url;
    }

    return (
        <Grid container spacing={2} className={classes.section} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
                <Card className={classes.userCard}>
                    <CardContent>
                        <Avatar className={classes.avatar}>
                            <Icons.AccountCircle />
                        </Avatar>
                        {
                            (userInfo && userInfo.attributes) &&
                            <>
                                <Typography variant="h5" className={classes.welcome}>Bienvenido, <b>{userInfo.attributes.name} {userInfo.attributes.family_name}</b></Typography>
                                <Typography variant="h6">{userInfo.attributes.email}</Typography>
                            </>
                        }
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
                <Card className={classes.summary}>
                    <CardContent>
                        <Typography variant="h4">Manager App</Typography>
                        <div>
                            Pronto mostraremos resumen de:
                            <ul className={classes.futureFunctions}>
                                <li>Facturación de Clientes</li>
                                <li>Tickets de Soporte</li>
                                <li>Satisfacción de clientes</li>
                                <li>Uso de servicios</li>
                            </ul>
                        </div>
                    </CardContent>
                </Card>
            </Grid>

        </Grid>
    );
}
export default HomePage;