import React, { useState } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Typography, Grid, CircularProgress } from '@material-ui/core';
import NeokodeButton from '../../components/NeokodeButton';
import { Link as RouterLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import user_access from '../../data/customer_user_access';
import { invokeApig } from '../../lib/awsLib';
import { validateEmail, validateName } from '../../lib/validate';

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  accessTitle: {
    marginTop: '40px',
  },
  buttonGoBack: {
    marginTop: '15px',
  },
}));

function CustomerUserForm(props) {
  const classes = useStyles();
  const readOnly = false;
  const id = props.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState({
    email: props.user ? props.user.email : '',
    name: props.user ? props.user.name : '',
    lastName: props.user ? props.user.lastName : '',
    phoneNumber: props.user ? props.user.phoneNumber : '',
    status: props.user ? props.user.status : '',
    enabled: props.user ? props.user.enabled : false,
    user_access: props.user ? props.user.user_access : {},
  });

  const createUser = (user) => {
    return invokeApig({
      path: `/account/user`,
      method: "POST",
      body: user
    });
  };

  const updateUser = (user) => {
    return invokeApig({
      path: `/account/user`,
      method: "PUT",
      body: user
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handleChangeAccess = (event) => {
    const { name, value } = event.target;
    const attr = name.split('_');
    const user_access = { ...user.user_access, [attr[1]]: [value] };
    setUser({ ...user, user_access: user_access });
  }

  const validate = () => {
    let message = undefined;
    if (!validateEmail(user.email)) {
      message = 'Debes ingresar el email correctamente';
    } else {
      if (!validateName(user.name)) {
        message = 'Debes ingresar el nombre correctamente';
      } else {
        if (!validateName(user.lastName)) {
          message = 'Debes ingresar el apellido correctamente';
        }
      }
    }
    return message;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const errorMsg = validate();
    if (errorMsg) {
      setMessage(errorMsg);
      setIsError(true);
      return;
    }
    const new_user = {
      email: user.email,
      name: user.name,
      lastName: user.lastName,
      phoneNumber: !!user.phoneNumber ? user.phoneNumber : '',
      status: user.status,
      enabled: user.enabled,
      user_access: user.user_access,
      owner: id
    };
    if (props.user) { //editar
      const response = await updateUser(new_user);
      if (response.errorMessage) {
        let msg = 'Error al modificar el usuario, por favor revisa los datos e intenta nuevamente';
        setMessage(msg);
        setIsError(true);
      } else {
        setMessage('El usuario se ha modificado correctamente');
        setIsSuccess(true);
      }
    } else { //crear
      const response = await createUser(new_user);
      if (response.errorMessage) {
        let msg = 'Error al crear el usuario, por favor revisa los datos e intenta nuevamente';
        if (response.errorType === 'UsernameExistsException') {
          msg = 'El usuario ya existe. Puedes eliminarlo y crearlo nuevamente o resetear la contraseña.'
        }
        setMessage(msg);
        setIsError(true);
      } else {
        setMessage('El usuario se ha creado correctamente');
        setIsSuccess(true);
      }
    }
    setIsLoading(false);
  };

  const handleGoBack = (event) => {
    event.preventDefault();
    if (props.onFinish) {
      props.onFinish(false);
    } else {
      props.history.push(`/customers/${id}/users`);
    }
  }

  if (isSuccess) {
    return (
      <>
        <Alert severity="success">{message}</Alert>
        <NeokodeButton variant="contained" component={RouterLink} to={`/customers/${id}/users`} className={classes.buttonGoBack}>Volver</NeokodeButton>
      </>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField label="Nombre" name="name" value={user.name} onChange={handleChange} required fullWidth className={classes.formControl} InputProps={{ readOnly: readOnly, }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Apellido" name="lastName" value={user.lastName} onChange={handleChange} required fullWidth className={classes.formControl} InputProps={{ readOnly: readOnly, }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Email" name="email" value={user.email} onChange={handleChange} required fullWidth className={classes.formControl} InputProps={{ readOnly: readOnly, }} />
          </Grid>
          {
            !!props.user &&
            <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Estado</InputLabel>
                  <Select name='enabled' value={user.enabled} onChange={handleChange} inputProps={{ readOnly: readOnly, }}>
                    <MenuItem value={true}>Activo</MenuItem>
                    <MenuItem value={false}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
             </Grid>
            </>
          }
        </Grid>
        <Typography variant="h6" className={classes.accessTitle}>Permisos</Typography>
        <Grid container spacing={2}>
          {
            Object.keys(user_access).map((key, index) => {
              const access_obj = user_access[key];
              return (
                <Grid item xs={12} sm={6} key={index}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel>{access_obj.name}</InputLabel>
                    <Select name={`access_${key}`} value={!!user && user.user_access[key] ? user.user_access[key] : ''} onChange={handleChangeAccess} inputProps={{ readOnly: readOnly, }}>
                      <MenuItem value="">Sin permiso</MenuItem>
                      {
                        access_obj.list.map((acc, index_access) => (
                          <MenuItem key={index_access} value={acc.value}>{acc.label}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              );
            })
          }
        </Grid>
        {
          isError &&
          <Alert severity="error">
            {message}
          </Alert>
        }
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              type="button"
              variant="contained"
              className={classes.button}
              onClick={handleGoBack}
              disabled={isLoading}
            >
              Volver
            </Button>
          </Grid>
          {
            !readOnly &&
            <Grid item>
              <NeokodeButton
                type="submit"
                variant="contained"
                className={classes.button}
                disabled={isLoading}
              >
                {
                  isLoading ?
                  <CircularProgress />
                  :
                  ( props.user ? 'Editar Usuario' : 'Agregar Usuario' )
                }
              </NeokodeButton>
            </Grid>
          }
        </Grid>
      </form>
    </>
  );
}

export default CustomerUserForm;