import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { TextField, Link, Typography, Container, CssBaseline, Avatar, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LogoNK from '../assets/images/logo-nk.png';
import { makeStyles } from '@material-ui/core/styles';
import NeokodeButton from '../components/NeokodeButton';
import ChangePasswordForm from '../containers/user/ChangePasswordForm';
import ForgotPasswordForm from '../containers/user/ForgotPasswordForm';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
        height: theme.spacing(20),
        width: theme.spacing(20),
    },
    logo: {
        height: '100%',
        width: 'auto',
    },
    appName: {
        backgroundColor: '#383838',
        color: '#fff',
        width: '100%',
        textAlign: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function LoginPage() {
    const classes = useStyles();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramVerification = queryParams.get('verification');

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(!!paramVerification);
    const [isVerificationCode, setIsVerificationCode] = useState(!!paramVerification);

    const signIn = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            setIsVerificationCode(false);
            const user = await Auth.signIn(username, password);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setShowChangePassword(true);
            } else {
                window.location.href = '/';
            }
        } catch (error) {
            let message = 'Error al iniciar sesión';
            if (error.code === 'PasswordResetRequiredException') {
                setIsVerificationCode(true);
                setShowForgotPassword(true);
                setIsLoading(false);
                return;
            } else {
            if (error.code === 'UserNotFoundException') {
                message = 'El usuario ingresado no existe';
            } else {
                if (error.code === 'NotAuthorizedException') {
                    message = 'La clave ingresada es incorrecta';
                }
            }}
            setErrorMessage(message);
            setIsError(true);
            setIsLoading(false);
        }
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();
        setIsVerificationCode(false);
        setShowForgotPassword(true);
    }

    const handleClose = () => {
        setIsError(false);
        setErrorMessage('');
    }

    if (showChangePassword) {
        return (
            <ChangePasswordForm username={username} password={password} />
        );
    }
    if (showForgotPassword) {
        return (
            <ForgotPasswordForm username={username} isVerificationCode={isVerificationCode}/>
        )
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <img src={LogoNK} alt="Logo" className={classes.logo} />
                </Avatar>
                <Typography component="h4" variant="h4" className={classes.appName}>
                    MANAGER
                </Typography>
                <Typography component="h5" variant="h5" className={classes.appName}>
                    Iniciar sesión
                </Typography>
                <form className={classes.form} noValidate onSubmit={signIn}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Usuario"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        disabled={isLoading}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        disabled={isLoading}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {
                        isError && (
                            <Alert severity="error" onClose={handleClose}>
                                {errorMessage}
                            </Alert>
                        )
                    }
                    <NeokodeButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                            <CircularProgress />
                            :
                            'Iniciar sesión'
                        }
                    </NeokodeButton>
                    <Link href="#" variant="body2" onClick={handleForgotPassword}>
                        ¿Olvidaste tu contraseña?
                    </Link>
                </form>
            </div>
        </Container>
    );
}

export default LoginPage;