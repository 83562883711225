import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import { invokeApig } from '../lib/awsLib';
import UserForm from '../containers/user/UserForm';
import NeokodeButton from '../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  gridMaxWidth: {
      [theme.breakpoints.up('md')]: {
          maxWidth: theme.breakpoints.values.md,
      },
  },
  paper: {
      padding: theme.spacing(2),
  },
  
}));

function UserPage(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async() => {
    if (id) {
        try {
            setIsLoading(true);
            const response = await invokeApig({
                path: `/user/${id}`,
            });
            if (response.code === 0) {
              setUser(response.data);
            } else {
              if (response.errorType === 'UserNotFoundException') {
                setMessage('El usuario no existe');
              } else {
                setMessage('No pudimos obtener los datos del usuario. Intanta nuevamente o comunícate con Soporte');
              }
              setIsError(true);
            }
        } catch (error) {
          setMessage('Error al obtener los datos del usuario. Intanta nuevamente o comunícate con Soporte');
          setIsError(true)
        }
        setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  const goUserList = () => {
    props.history.push('/users');
  }

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxWidth}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            {!!id ? 'Editar' : 'Crear'} usuario Manager
          </Typography>
          {
            isLoading ?
            <CircularProgress />
            :
            (
              isError ?
              <>
                <Alert severity="error">
                    {message}
                </Alert>
                <NeokodeButton
                  type="button"
                  variant="contained"
                  className={classes.button}
                  component={RouterLink}
                  to="/users"
                >
                  Volver al listado de usuarios
                </NeokodeButton>
              </>
              :
              <UserForm {...props} user={user} onFinish={goUserList} />
            )
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UserPage;
